/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

const $ = require("jquery")

export const onRouteUpdate = () => {

  var mypos = $(window).scrollTop();
  var up = false;
  var newscroll;
  
  if (mypos > 20) {
      $('.navbar').removeClass('nav-bg-transparent');
  }
  
  
  $(window).on('scroll', function() {
      newscroll = $(window).scrollTop();
      if (((newscroll > mypos) && !up ) && newscroll > 30) {
          $('.navbar').removeClass('scrollMenuTop');
          $('.scroll-bar').removeClass('scrollbartop');
          up = !up;
      } else if (((newscroll < mypos) && up)) {
          if ((mypos-newscroll) > 10) {
              $('.navbar').addClass('scrollMenuTop');
              $('.scroll-bar').addClass('scrollbartop');
              up = !up;
          }
      }
  
      if (newscroll <= 20) {
        $('.navbar').addClass('nav-bg-transparent');
  
      } else {
        $('.navbar').removeClass('nav-bg-transparent');
    }
  
      mypos = newscroll;
  })  

  $('.f-thumb-url').mouseover(function(){
    $(".f-title-url").addClass('active');
  });
  $('.f-thumb-url').mouseout(function(){
    $(".f-title-url").removeClass('active');
  });

  $('.f-title-url').mouseover(function(){
    $('.f-thumb-url').addClass('active');
  });
  $('.f-title-url').mouseout(function(){
    $('.f-thumb-url').removeClass('active');
  });
  $('.thumb-url').mouseover(function(){
    var x = $(this).attr("data-url");
    $(x).addClass('active');
  });
  $('.thumb-url').mouseout(function(){
    var y = $(this).attr("data-url");
    $(y).removeClass('active');
  });

  $('.title-url').mouseover(function(){
    var x = $(this).attr("data-url");
    $(x).addClass('active');
  });
  $('.title-url').mouseout(function(){
    var y = $(this).attr("data-url");
    $(y).removeClass('active');
  });
  
  $('#navbar-toggle').click(function(e){
    $('body').toggleClass('o-hidden');
    $('#navbar-toggle').toggleClass('collapsed');
    $("#overlay").toggleClass('active');
    $('.navbar').toggleClass('trasparent-nav');
    return false;       
  });
  
  $('.tab-btn').click(function(e){
    $('.tab-btn').removeClass("active");
    $(this).addClass("active");
    var id = $(this).attr('data-id');
    $('.wmtab-content').removeClass("active");
    $(id).addClass('active');
    return false;       
  });

  $('.services-checkbox').on('change', function() {
    var $input = $(this).is(":checked");
    if($input === true) {
      $(this).closest('.checkbox-label').addClass('checked');
    } else {
      $(this).closest('.checkbox-label').removeClass('checked');
    }
    
  });
}

$(window).on('scroll', function() {
  
  /* height scrolled from top */
  var scrolled_top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  
  /* total scrollable height */
  var total_height = (document.documentElement.scrollHeight || document.body.scrollHeight) - (document.documentElement.clientHeight || document.body.clientHeight || window.innerHeight) 
  var cs_height = $('.case-studies-slider').innerHeight();
  if(cs_height == null) {
    cs_height = 0;
  }
  
  var f_height  = $('footer').innerHeight();

  var to_scroll = total_height - (cs_height + f_height);
  /* percentage of height scrolled */
  var horizontal_width = (scrolled_top/to_scroll)*100;
  
  /* assigning calculated width to progress bar */
  document.getElementById('horizontal_scroll').style.width = horizontal_width + '%';
});  